<template>
    <div class="dialog-container">
        <el-dialog
            title="会员卡详情"
            :visible.sync="showFlag"
            :before-close="closeDialog"
            center
            :close-on-click-modal="false"
            top="2vh"
            width="900px"
            >
            <el-form ref="form" class="detail-form" label-width="120px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="发行商户:">
                            <div class="t-item">{{formatterMerchant(form.merchantId)}}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="会员卡类型:">
                            <div class="t-item">{{form.type === 1 ? '期限卡' : form.type === 2 ? '次卡' : '储值卡'}}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="会员卡名称:">
                            <div class="t-item">{{form.name}}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="激活方式:">
                            <div class="t-item">{{form.activeType === 1 ? '自动激活' : form.activeType === 2 ? '现场激活' : form.activeType === 3 ? '首次使用激活' : '置顶日期激活'}}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="会员卡状态:">
                            <div class="t-item">{{form.status === 1 ? '上架' : '下架'}}</div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="line"></div>
                <el-row>
                    <el-col :span="12" v-if="form.type !== 3">
                        <el-form-item label="有效天数:">
                            <div class="t-item">{{form.termConvert ? form.termConvert.termStr : ''}}</div>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="form.type === 2" :span="12">
                        <el-form-item label="有效次数:">
                            <div class="t-item">{{form.cardTypeRightEntity.useLimitQty}}</div>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="form.type === 3" :span="12">
                        <el-form-item label="最低储值金额:">
                            <div class="t-item">{{form.cardTypeRightEntity.minMoneyLimit}}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="可通行区域:">
                            <div class="t-item"><span v-for="(item, index) in form.venueDef" :key="index"><span v-if="index > 0">,</span>{{formatterVenue(item)}}</span></div>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="form.type !== 3" :span="12">
                        <el-form-item label="适用运动项目:">
                            <div class="t-item"><span v-for="(item, index) in form.sportsDef" :key="index"><span v-if="index > 0">,</span>{{valueToText(item, sportList, 'id', 'label' )}}</span></div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="是否可退卡:">
                            <div class="t-item">{{form.cardTypeRightEntity.availableExitMark === 1 ? '可退' : '不可退卡'}}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="是否可共享:">
                            <div class="t-item">{{form.cardTypeRightEntity.shareLimitQty === 0 ? '否' : '是'}}</div>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="form.cardTypeRightEntity.shareLimitQty > 0" :span="12">
                        <el-form-item label="最大共享人数:">
                            <div class="t-item">{{form.cardTypeRightEntity.shareLimitQty}}人</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="是否可转卡:">
                            <div class="t-item">{{form.cardTypeRightEntity.isTransfer ? '是' : '否'}}</div>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="form.cardTypeRightEntity.isTransfer" :span="12">
                        <el-form-item label="是否可多次转卡:">
                            <div class="t-item">{{form.cardTypeRightEntity.transferLimitQty ? '是' : '否'}}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="是否可停卡:">
                            <div class="t-item">{{form.cardTypeRightEntity.pauseLimitQty === 0 ? '否' : '是'}}</div>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="form.cardTypeRightEntity.pauseLimitQty > 0" :span="12">
                        <el-form-item label="可停卡次数:">
                            <div class="t-item">{{form.cardTypeRightEntity.pauseLimitQty}}次</div>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="form.cardTypeRightEntity.pauseLimitQty > 0" :span="12">
                        <el-form-item label="每次停卡天数:">
                            <div class="t-item">{{form.cardTypeRightEntity.pauseDayLimitQty}}天</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="时段限制:">
                            <div class="t-item">
                                <div class="weekBox">
                                    <div v-for="(item, index) in form.periodDef" :key="index" class="week">
                                        <div>{{getweek(item.day)}}</div>
                                        <div>{{item.start}}~{{item.end}}</div>
                                    </div>
                                </div>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" v-if="form.type === 3">
                        <el-form-item label="提前可预约天数:">
                            <div class="t-item">{{form.cardTypeRightEntity.cutOffBufferQty}}天</div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="line"></div>
                 <el-row>
                    <el-col v-if="form.type !== 3" :span="12">
                        <el-form-item label="售价:">
                            <div class="t-item">{{form.defaultPrice}}元</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="会员卡封面:">
                            <div class="t-item">
                                <el-image
                                  class="card-fm"
                                  fit="contain"
                                  :src="form.cardImgPath"
                                >
                                    <div slot="error" class="image-slot">
                                        <i class="el-icon-picture-outline"></i>
                                    </div>
                                </el-image>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="会员卡简介:">
                            <div class="t-item pb-20" v-html="form.introduce">
                                <!-- <el-input
                                    type="textarea"
                                    readonly
                                    :rows="4"
                                    v-model="textarea">
                                </el-input> -->
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="购卡协议:">
                            <div class="t-item pb-20" v-html="form.agreement">
                                <!-- <el-input
                                    type="textarea"
                                    readonly
                                    :rows="4"
                                    v-model="textarea">
                                </el-input> -->
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template slot="footer">
                <el-button class="footer-confirm" @click="closeDialog">返回</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import apis from "@/apis";
import { mapState } from "vuex";
import { changeInfo } from "@/utils/index";

export default {
    data() {
        return {
            showFlag: false,
            merchantBox: [], // 商户下拉框值
            form: {
                merchantId: '',
                type: '',
                name: '',
                activeType: '',
                status: '',
                term: '',
                agreement: '',
                introduce: '',
                cardImgPath: '',
                defaultPrice: '',
                periodDef: [],
                venueDef: '',
                sportsDef: [],
                termConvert: {
                    termStr: '',
                },
                cardTypeRightEntity: {
                    availableExitMark: '',
                    availableTo: '',
                    cardTypeId: '',
                    cutOffBufferQty: '',
                    delFlag: '',
                    description: '',
                    minMoneyLimit: '',
                    pauseDayLimitQty: '',
                    pauseLimitQty: '',
                    shareLimitQty: '',
                    sportsDef: '',
                    transferLimitQty: '',
                    useLimitQty: '',
                },
            },
            week: [
                { value: "1", text: "周一" },
                { value: "2", text: "周二" },
                { value: "3", text: "周三" },
                { value: "4", text: "周四" },
                { value: "5", text: "周五" },
                { value: "6", text: "周六" },
                { value: "7", text: "周日" },
            ],
            venueBox: [],
            sportList: [], // 运动项目
        };
    },
    computed: {
        ...mapState(['DROPDOWNBOX', "TOKEN", "venueId", "venueList", 'merchantAccountDetailInfoDTO']),
    },
    methods: {
        // 发行商户
        formatterMerchant(data) {
            let returnData = ''
            if (this.merchantBox.length > 0) {
                this.merchantBox.forEach((item) => {
                if (item.merchantId === data) {
                    returnData = item.merchantName
                }
                })
            } else {
                this.$http.get(apis.findAllMerchant).then((res) => {
                if (res.data.code === 0 && res.data.data) {
                    res.data.data.forEach((item) => {
                    if (item.merchantId === data) {
                        returnData = item.merchantName
                    }
                    })
                }
                })
            }
            return returnData
        },
        formatterVenue(data) {
            console.log(data, 132132)
            let returnData = ''
            if (this.venueBox.length > 0) {
                this.venueBox.forEach((item) => {
                    if (item.id === data) {
                        returnData = item.venueName
                    }
                })
            } else {
                this.$http.get(`${apis.getVenueListByMerchantId}?merchantId=${this.merchantAccountDetailInfoDTO.merchantId}`).then((res) => {
                    if (res.data.code === 0) {
                        res.data.data.forEach((item) => {
                            if (item.id === data) {
                                returnData = item.venueName
                            }
                        })
                    }
                })
            }
            return returnData
        },
        // 获取星期方法
        getweek(data) {
            const arr = []
            this.week.forEach((item) => {
                data.forEach((el) => {
                    if (el === item.value) {
                        arr.push(item.text)
                    }
                })
            })
            return arr.join(',')
        },
        valueToText(value, list, keyValue, keyText) {
            return changeInfo(value, list, keyValue, keyText)
        },
        init() {
            this.$refs.form.resetFields();
        },
        // 弹框打开触发
        showDialog() {
            this.showFlag = true
            this.$http.get(apis.findAllMerchant).then((res) => {
                if (res.data.code === 0) {
                    this.merchantBox = res.data.data
                }
            })
            // 运动项目下拉框
            this.$http.get(apis.dictGetSports).then((res) => {
                if (res.data.code === 0) {
                    this.sportList = res.data.data.SPORT_PROJECT
                }
            })
            // 获取通行场区下拉取值
            this.$http.get(`${apis.getVenueListByMerchantId}?merchantId=${this.merchantAccountDetailInfoDTO.merchantId}`).then((res) => {
                if (res.data.code === 0) {
                    this.venueBox = res.data.data
                }
            })
        },
        getDetails(id) {
            this.cardID = id
            this.$http.get(`${apis.cardTypeGetById}?id=${id}`).then((res) => {
                console.log('cardDetails', res);
                if (res.data.code === 0) {
                    const data = res.data.data
                    this.form = data
                    this.form.periodDef = JSON.parse(this.form.periodDef)
                    this.form.venueDef = this.form.venueDef.split(',')
                    this.form.sportsDef = []
                    if (this.form.type === 1) {
                        this.form.sportsDef = data.cardTypeRightEntity.sportsDef.split(',')
                    }
                    if (this.form.type === 2) {
                        this.form.sportsDef = [data.cardTypeRightEntity.sportsDef]
                    }
                    this.showDialog()
                } else {
                    this.$message.info(res.data.msg)
                }
            })
        },
        closeDialog() {
            this.init()
            this.$emit('closeDialog', false)
            this.$refs.form.resetFields();
            this.showFlag = false
        },
    },
}
</script>
<style lang='scss' scoped>
.detail-form{
    .el-form-item{ margin-bottom: 0!important;}
    .t-item{ font-weight: bold; color: #333333;
        .card-fm{ height: 120px; width:150px;}
    }
    .line{width: 100%;height: 1px; background: #eeeeee; margin: 10px 0;}
    .weekBox {
        display: block;
        // margin-left: 30px;
        .week {
            display: flex;
            .del {
                color: red;
                cursor: pointer;
                margin-left: 10px;
            }
        }
    }
}
.pb-20{
    padding-bottom: 20px;
}
</style>
<style lang="scss">
.pb-20{
    img{
         width: 100%;
    }
}
</style>
