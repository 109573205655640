<template>
    <div class="dialog-container">
        <el-dialog
            title="添加可用时段"
            :visible.sync="showFlag"
            center
            :close-on-click-modal="false"
            append-to-body
            top="10vh"
            width="750px"
            >
            <el-form ref="form" :model="form" class="detail-form" label-width="120px" :rules="rules">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="可用日期" prop="day">
                            <el-checkbox-group v-model="form.day">
                                <el-checkbox
                                v-for="(item, index) in baseWeekList"
                                :label="item.value"
                                :key="index"
                                >{{ item.text }}
                                </el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="起始时间" prop="start">
                            <el-time-select
                                placeholder="起始时间"
                                v-model="form.start"
                                :picker-options="{
                                start: '00:00',
                                step: '00:30',
                                end: '24:00',
                                maxTime: form.end
                                }">
                            </el-time-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="结束时间" prop="end">
                            <el-time-select
                                placeholder="结束时间"
                                v-model="form.end"
                                :picker-options="{
                                start: '00:00',
                                step: '00:30',
                                end: '24:00',
                                minTime: form.start
                                }">
                            </el-time-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template slot="footer">
                <el-button class="footer-confirm" @click="save">{{$t('save')}}</el-button>
                <el-button class="footer-cancel" @click="closeDialog">{{$t('cancel')}}</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            showFlag: false,
            form: {
                day: [],
                start: '',
                end: '',
            },
            baseWeekList: [],
            rules: {
                day: [{ required: true, message: '请选择可用日期', trigger: ['blur', 'change'] }],
                start: [{ required: true, message: '请选择开始时间', trigger: ['blur', 'change'] }],
                end: [{ required: true, message: '请选择结束时间', trigger: ['blur', 'change'] }],
            },
        };
    },
    computed: {
        ...mapState(['DROPDOWNBOX', "TOKEN", "venueId", "venueList"]),
    },
    methods: {
        init() {
            this.$refs.form.resetFields()
        },
        // 弹框打开触发
        showDialog(oxzWeeks = []) {
            this.showFlag = true
            const weeks = [
                { value: "1", text: "周一" },
                { value: "2", text: "周二" },
                { value: "3", text: "周三" },
                { value: "4", text: "周四" },
                { value: "5", text: "周五" },
                { value: "6", text: "周六" },
                { value: "7", text: "周日" },
            ]
            this.baseWeekList = weeks.filter((item) => {
                return !oxzWeeks.includes(item.value)
            })
        },
        save() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.$emit('save', this.form)
                    this.$nextTick(() => {
                        this.closeDialog()
                    })
                }
            })
        },
        closeDialog() {
            this.showFlag = false
            this.init()
        },
        // afterCloseDialog()
    },
}
</script>
<style lang='scss' scoped>
.el-checkbox{margin-right: 30px!important;}
</style>
