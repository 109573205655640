<!--
 * @Author: zhongtao
 * @LastEditors: zhongtao
-->
<template>
  <div class="vipCardManagement">
    <div class="search-container search-container-new">
      <el-row >
        <el-col :span="20">
          <el-form ref="form" :model="form" label-width="100px">
            <el-row>
              <el-col :span="6">
                <el-form-item prop="merchantId" label="发行商户：">
                  <!-- <el-select v-model="form.merchantId" style="width: 100%">
                    <el-option
                      v-for="item in merchantBox"
                      :key="item.merchantId"
                      :label="item.merchantName"
                      :value="item.merchantId"
                    ></el-option>
                  </el-select> -->
                  <el-input v-model="merchantName" disabled></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item prop="name	" label="卡名称：">
                  <el-input clearable v-model="form.name" placeholder=""></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item prop="type" label="卡类型：">
                  <el-select v-model="form.type" style="width: 100%">
                    <el-option
                      v-for="item in typeList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item prop="status	" label="卡状态：">
                  <el-select v-model="form.status" style="width: 100%">
                    <el-option
                      v-for="item in statusList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-col>
        <el-col :span="4" style="text-align:right">
          <el-button @click="cleanForm" class="clean-button">{{$t('reset')}}</el-button>
          <el-button @click="searchEvent" class="search-button">{{$t('search')}}</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="contant">
      <noData v-if="tableData.length === 0" />
      <div v-else :style="{height: tableHeight + 'px'}" class="card-contant">
        <div v-for="(item, index) in tableData" :key="index">
          <!-- 期限卡 -->
          <div class="card" v-if="item.type === 1">
            <div class="heard">
              <div class="title" :title="item.name">{{item.name}}</div>
              <div class="opretion">
                <div class="cut" @click="openDetailDialog(item.id)">详情</div>
                <div class="cut" @click="openIssueDialog(item.id, 9, item.status)" v-if="buttonAuth.includes('memberCard:cardType:updateStatus')">{{item.status === 2 ? '上架' : '下架'}}</div>
                <div class="cut" @click="openEditCardDrawer(item.id)" v-if="item.status === 2 && buttonAuth.includes('memberCard:cardType:update')">编辑</div>
                <div @click="openDeleteDialog(item.id, 1)" v-if="item.status === 2 && buttonAuth.includes('memberCard:cardType:delete')">删除</div>
              </div>
            </div>
            <div :class="item.status === 1 ? 'sale' : 'noSale'">{{item.status === 1 ? '上架' : '下架'}}</div>
            <div class="second">
              <div class="merchant">发行商户：{{formatterMerchant(item.merchantId)}}</div>
              <div class="money">￥{{item.defaultPrice}}</div>
            </div>
            <div class="sameStyle">有效期限：{{item.termStr}}</div>
            <div class="sameStyle">运动项目：{{item.sportList && item.sportList.length > 0 ? item.sportList.join(',') : ''}}</div>
          </div>
          <!-- 次卡 -->
          <div class="card timeCard" v-if="item.type === 2">
            <div class="heard">
              <div class="title" :title="item.name">{{item.name}}</div>
              <div class="opretion">
                <div class="cut" @click="openDetailDialog(item.id)">详情</div>
                <div class="cut" @click="openIssueDialog(item.id, 9, item.status)" v-if="buttonAuth.includes('memberCard:cardType:updateStatus')">{{item.status === 2 ? '上架' : '下架'}}</div>
                <div class="cut" @click="openEditCardDrawer(item.id)" v-if="item.status === 2 && buttonAuth.includes('memberCard:cardType:update')">编辑</div>
                <div @click="openDeleteDialog(item.id, 1)" v-if="item.status === 2 && buttonAuth.includes('memberCard:cardType:delete')">删除</div>
              </div>
            </div>
            <div :class="item.status === 1 ? 'sale' : 'noSale'">{{item.status === 1 ? '上架' : '下架'}}</div>
            <div class="second">
              <div class="merchant">发行商户：{{formatterMerchant(item.merchantId)}}</div>
              <div class="money">￥{{item.defaultPrice}}</div>
            </div>
            <div class="sameStyle">有效期限：{{item.termStr}}</div>
            <div class="sameStyle">可用次数：{{item.useLimitQty || 0}}次</div>
            <div class="sameStyle">运动项目：{{item.sportList && item.sportList.length > 0 ? item.sportList.join(',') : ''}}</div>
          </div>
          <!-- 储值卡 -->
          <div class="card storeCard" v-if="item.type === 3">
            <div class="heard">
              <div class="title" :title="item.name">{{item.name}}</div>
              <div class="opretion">
                <div class="cut" @click="openDetailDialog(item.id)">详情</div>
                <div class="cut" @click="openIssueDialog(item.id, 9, item.status)" v-if="buttonAuth.includes('memberCard:cardType:updateStatus')">{{item.status === 2 ? '上架' : '下架'}}</div>
                <div class="cut" @click="openEditCardDrawer(item.id)" v-if="item.status === 2 && buttonAuth.includes('memberCard:cardType:update')">编辑</div>
                <div @click="openDeleteDialog(item.id, 1)" v-if="item.status === 2 && buttonAuth.includes('memberCard:cardType:delete')">删除</div>
              </div>
            </div>
            <div :class="item.status === 1 ? 'sale' : 'noSale'">{{item.status === 1 ? '上架' : '下架'}}</div>
            <div class="second">
              <div class="merchant">发行商户：{{formatterMerchant(item.merchantId)}}</div>
              <div class="money">
                <div>￥{{item.minMoneyLimit}}</div>
                <div class="low">最低储值金额</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="page-content">
        <div>
          <el-button class="default-button" v-if="buttonAuth.includes('memberCard:cardType:add')" @click="openAddCardDrawer(true)">{{$t('add')}}</el-button>
        </div>
        <!-- <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="form.pageNum"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="form.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="form.total">
        </el-pagination> -->
      </div>
    </div>
    <!-- 详情弹窗 -->
    <detail-dialog
      ref="detailDialog"
      :Language="Language"
      :show="addDialog.visible"
      :isEdit="addDialog.isEdit"
      @closeDialog="changeAddDialog"
    ></detail-dialog>
    <!--新增编辑会员卡抽屉弹窗 -->
    <add-card-drawer
      ref="addCardDrawer"
      :isEdit="isEdit"
      :Language="Language"
      @closeDialog="changeAddDialog"
    ></add-card-drawer>
    <!-- 确认弹窗 -->
    <confirm-dialog
    ref="confirmDialog"
    :text="confirmDialog.text"
    :show="confirmDialog.visible"
    @sureDialog="sureConfirmDialog"
    @closeDialog="changeConfirmDialog"
    ></confirm-dialog>
  </div>
</template>
<script>
import mixin from "@/mixins/index";
import apis from "@/apis";
import { mapState } from "vuex";
// import addDialog from "./dialog/addDialog.vue";
// import confirmDialog from "@/components/confirmDialog.vue";
import confirmDialog from "@/components/confirmDialogNew.vue";
import IndexClass from "./indexClass";
import noData from "@/components/noData.vue";
import detailDialog from "./components/detailDialog.vue";
import addCardDrawer from "./components/addCardDrawer.vue";

export default {
  mixins: [mixin],
//   components: { addDialog, confirmDialog },
  components: {
    confirmDialog,
    noData,
    detailDialog,
    addCardDrawer,
  },
  data() {
    return {
      sportsProjectList: [],
      venueName: "",
      form: new IndexClass("form"), // 实例化一个表单的变量
      tableData: [],
      merchantBox: [], // 商户下拉框值
      typeList: [ // 卡状态下拉框值
        {
          value: 1,
          label: '期限卡',
        },
        {
          value: 2,
          label: '次卡',
        },
        {
          value: 3,
          label: '储值卡',
        },
      ],
      statusList: [ // 卡状态下拉框值
        {
          value: 1,
          label: '上架',
        },
        {
          value: 2,
          label: '下架',
        },
      ],
      addDialog: {
        visible: false,
        isEdit: false,
      },
      confirmDialog: {
        visible: false,
        type: 1, //1:删除 9：发布
        status: '',
        text: '',
        data: {},
      },
      isEdit: false,
      merchantName: '',
    };
  },
  mounted() {},
  activated() {
    this.$http.get(apis.findAllMerchant).then((res) => {
      if (res.data.code === 0) {
        this.merchantBox = res.data.data
        this.merchantBox.forEach((item) => {
          if (item.merchantId === this.merchantAccountDetailInfoDTO.merchantId) {
            this.merchantName = item.merchantName
          }
        });
      }
    })
  },
  computed: {
    ...mapState(["Language", "DROPDOWNBOX", "TOKEN", "venueId", 'merchantAccountDetailInfoDTO']),
  },
  methods: {
    // 发行商户
    formatterMerchant(data) {
      let returnData = ''
      if (this.merchantBox.length > 0) {
        this.merchantBox.forEach((item) => {
          if (item.merchantId === data) {
            returnData = item.merchantName
          }
        })
      } else {
        this.$http.get(apis.findAllMerchant).then((res) => {
          if (res.data.code === 0 && res.data.data) {
            res.data.data.forEach((item) => {
              if (item.merchantId === data) {
                returnData = item.merchantName
              }
            })
          }
        })
      }
      return returnData
    },
    // 打开详情弹框
    openDetailDialog(id) {
      this.$refs.detailDialog.getDetails(id)
    },
    // 打开购卡抽屉
    openAddCardDrawer() {
      this.isEdit = false
      this.$refs.addCardDrawer.showDrawer()
    },
    // 编辑
    openEditCardDrawer(id) {
      this.isEdit = true
      this.$refs.addCardDrawer.getDetails(id)
    },
    // 删除
    modelDelete() {
      const formData = {
        id: this.confirmDialog.data.id,
      };
      this.$http
        // .delete(`${apis.delMontiorDevice}?id=${this.confirmDialog.data.id}`)
        .post(apis.cardTypedeleteById, formData)
        .then((res) => {
          if (res.data.code === 0) {
            this.$refs.confirmDialog.closeDialog();
            this.searchEvent();
          }
        });
    },
    modelStatus() {
      const formData = {
        id: this.confirmDialog.data.id,
        status: this.confirmDialog.status === 1 ? 2 : 1,
      };
      this.$http
        // .delete(`${apis.delMontiorDevice}?id=${this.confirmDialog.data.id}`)
        .post(apis.cardTypeupdateStatus, formData)
        .then((res) => {
          if (res.data.code === 0) {
            this.$refs.confirmDialog.closeDialog();
            this.$message.success("操作成功!");
            this.searchEvent();
          }
        });
    },
    // 清空按钮
    cleanForm() {
      this.$refs.form.resetFields();
      this.form = new IndexClass("form");
      this.$nextTick(() => {
        this.searchEvent();
      });
    },
    // 查询
    searchData() {
      this.$http
        .get(`${apis.cardTypeFind}?merchantId=${this.merchantAccountDetailInfoDTO.merchantId}&name=${this.form.name}&status=${this.form.status}&type=${this.form.type}`)
        .then((res) => {
          if (res.data.code === 0) {
            this.tableData = res.data.rows;
            this.form.total = res.data.total;
          }
        });
    },
    // 修改数据回显
    changeAddDialog(dialogStatus, isEdit = false, data = {}) {
      if (dialogStatus) {
        this.addDialog.isEdit = isEdit;
        if (isEdit) {
          // 调用vuex里面的获取数据的业务，如果要在这里写接口，需要改成Promise
          this.addDialog.data = data;
          this.$refs["addDialog"].getDataQuery(data);
        }
      } else {
        this.searchEvent();
      }
      this.$nextTick(() => {
        this.addDialog.visible = dialogStatus;
      });
    },
    /**
     * @function 改变确认/删除弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Number} type 打开的弹窗类型，1为删除确认
     */
    changeConfirmDialog(dialogStatus, type = 1, status = 1) {
      console.log(status)
      this.confirmDialog.type = type;
      this.confirmDialog.status = status;
      this.confirmDialog.visible = dialogStatus;
      if (!dialogStatus) {
        this.confirmDialog.data.id = "";
        this.searchEvent();
      }
    },
    /**
     * 删除和发布点击确认
     * id: 对应的id值
     * type 1：删除 9:发布
     */
    /* eslint-disable */
    openDeleteDialog(id, type) {
      this.confirmDialog.type = type;
      this.confirmDialog.text = '确定删除该会员卡吗?';
      this.confirmDialog.data.id = id;
      this.changeConfirmDialog(true, type);
    },
    openIssueDialog(id, type, status) {
      this.confirmDialog.type = type;
      this.confirmDialog.text = status === 1 ? '确认下架该会员卡吗?' : '确认上架该会员卡吗?';
      this.confirmDialog.data.id = id;
      this.changeConfirmDialog(true, type, status);
    },
    openDeleteDialogNew(id, type) {
      if (type === 2) {
        this.$message.success("敬请期待!");
      }
    },
    /**
     * @function 当确认弹窗点击确定的时候触发
     */
    sureConfirmDialog() {
      // 如果一个页面有多个弹窗，视情况决定用if语句还是二元表达式对api进行判断
      if (this.confirmDialog.type === 1) { // 删除
        this.modelDelete();
      } else if (this.confirmDialog.type === 9) { // 上下架
        this.modelStatus()
      }
    },
  },
};
</script>
<style lang="less" scoped>
@import "~@/assets/styles/mixin.scoped.less";
</style>
<style lang="less" scoped>
@import "~@/assets/styles/mixin.less";
.vipCardManagement {
  .page-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
  }
  .contant {
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #E4E9F1;
    margin: 0 15px;
    padding: 20px;
    .card-contant {
      overflow-y: auto;
    }
    .card {
      width: 300px;
      height: 175px;
      background: url('../../../assets/images/qixianka.png');
      position: relative;
      padding: 15px;
      box-sizing: border-box;
      float: left;
      margin: 0 10px 10px 10px;
      .heard {
        display: flex;
        justify-content: space-between;
        .title {
          font-size: 18px;
          font-family: SourceHanSansSC-Bold, SourceHanSansSC;
          font-weight: bold;
          color: #FFFFFF;
          width: 127px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        .opretion {
          display: flex;
          height: 28px;
          background: #C5A87D;
          border-radius: 14px;
          padding: 0 8px;
          align-items: center;
          font-size: 12px;
          font-family: SourceHanSansSC-Regular, SourceHanSansSC;
          font-weight: 400;
          color: #FFFFFF;
          cursor: pointer;
          .cut {
            padding-right: 4px;
            margin-right: 4px;
            border-right: 1px solid rgba(255, 255, 255, 0.3);
          }
        }
      }
      .sale {
        width: 54px;
        height: 20px;
        background: rgba(255, 255, 255, 0.8);
        border-radius: 14px;
        font-size: 14px;
        font-family: SourceHanSansSC-Medium, SourceHanSansSC;
        font-weight: 500;
        color: #439BFF;
        text-align: center;
      }
      .noSale {
        width: 54px;
        height: 20px;
        background: rgba(255, 255, 255, 0.8);
        border-radius: 14px;
        font-size: 14px;
        font-family: SourceHanSansSC-Medium, SourceHanSansSC;
        font-weight: 500;
        color: #9A9A9A;
        text-align: center;
      }
      .second {
        display: flex;
        justify-content: space-between;
        margin-top: 6px;
        align-items: center;
        .merchant {
          font-size: 12px;
          font-family: SourceHanSansSC-Regular, SourceHanSansSC;
          font-weight: 400;
          color: #987B54;
        }
        .money {
          font-size: 20px;
          font-family: SourceHanSansSC-Bold, SourceHanSansSC;
          font-weight: bold;
          color: #987B54;
        }
      }
      .sameStyle {
        margin-top: 7px;
        font-size: 12px;
        font-family: SourceHanSansSC-Regular, SourceHanSansSC;
        font-weight: 400;
        color: #987B54;
      }
    }
    .timeCard {
      background: url('../../../assets/images/cika.png');
      .heard {
        .opretion {
          background: #C1BFBC;
        }
      }
      .second {
        .merchant {
          color: #8B8885;
        }
        .money {
          color: #8B8885;
        }
      }
      .sameStyle {
        color: #8B8885;
      }
    }
    .storeCard {
      background: url('../../../assets/images/chuzhika.png');
      .sale {
        margin-top: 5px;
      }
      .second {
        .merchant {
          color: #6D8898;
        }
        .money {
          color: #6D8898;
          text-align: right;
          .low {
            font-size: 12px;
            font-weight: 400
          }
        }
      }
      .sameStyle {
        color: #6D8898;
      }
    }
  }
}
</style>
