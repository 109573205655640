/*
 * @Author: zhongtao
 * @LastEditors: zhongtao
 */
import pageClass from '@/unit/pageClass'

class searchFrom extends pageClass {
  constructor(type) {
    super()
    if (type === 'form') {
      this.merchantId = '' // 商户id
      this.name = '' // 卡名称
      this.type = '' // 卡类型 1期限卡，2次卡，3储值卡
      this.status = '' // 状态 1上架，2下架
    }
  }
}
export default searchFrom
